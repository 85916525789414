import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import styles from './PasswordGenerator.module.css';
import { toast } from 'react-toastify';
import { CommonPasswords } from '../../../utils/CommonPasswords';
import {Hero} from "../../../components/Hero";
import {SEO} from "../../../components/SEO";

export const PasswordGenerator: React.FC = () => {
    const [length, setLength] = useState<number>(16);
    const [includeUppercase, setIncludeUppercase] = useState<boolean>(true);
    const [includeLowercase, setIncludeLowercase] = useState<boolean>(true);
    const [includeNumbers, setIncludeNumbers] = useState<boolean>(true);
    const [includeSymbols, setIncludeSymbols] = useState<boolean>(true);
    const [avoidAmbiguous, setAvoidAmbiguous] = useState<boolean>(false);
    const [excludeKeywordsOption, setExcludeKeywordsOption] = useState<boolean>(false);
    const [excludeKeywords, setExcludeKeywords] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [strength, setStrength] = useState<string>('');

    useEffect(() => {
        generatePassword();
        // We can safely disable the exhaustive-deps rule for this effect
        // because we want it to run whenever any dependency changes.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        length,
        includeUppercase,
        includeLowercase,
        includeNumbers,
        includeSymbols,
        avoidAmbiguous,
        excludeKeywordsOption,
        excludeKeywords,
    ]);

    const generatePassword = () => {
        let characterSet = '';
        if (includeUppercase) characterSet += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (includeLowercase) characterSet += 'abcdefghijklmnopqrstuvwxyz';
        if (includeNumbers) characterSet += '0123456789';
        if (includeSymbols) characterSet += '!@#$%^&*()_+-=[]{}|;:\'",.<>/?';

        if (avoidAmbiguous) {
            characterSet = characterSet.replace(/[Il1O0]/g, '');
        }

        if (characterSet === '') {
            setPassword('');
            setStrength('Select at least one character type');
            return;
        }

        let generatedPassword = '';
        let previousChar = '';

        while (generatedPassword.length < length) {
            const character = characterSet.charAt(Math.floor(Math.random() * characterSet.length));

            // Avoid continuous identical characters if avoidAmbiguous is true
            if (avoidAmbiguous && character === previousChar) {
                continue;
            }

            generatedPassword += character;
            previousChar = character;
        }

        // Exclude keywords if option is selected
        if (excludeKeywordsOption && excludeKeywords.trim() !== '') {
            const keywordsArray = excludeKeywords
                .split(',')
                .map((k) => k.trim().toLowerCase())
                .filter((k) => k.length > 0);

            const passwordLower = generatedPassword.toLowerCase();
            const containsKeyword = keywordsArray.some((keyword) => passwordLower.includes(keyword));

            if (containsKeyword) {
                // Regenerate the password if it contains any of the keywords
                generatePassword();
                return;
            }
        }

        // Check against common passwords
        const isCommonPassword = CommonPasswords.some(
            (commonPwd) =>
                commonPwd.toLowerCase() === generatedPassword.toLowerCase() ||
                generatedPassword.toLowerCase().includes(commonPwd.toLowerCase())
        );

        if (isCommonPassword) {
            // Regenerate the password if it matches or is similar to a common password
            generatePassword();
            return;
        }

        setPassword(generatedPassword);
        evaluateStrength(generatedPassword);
    };

    const evaluateStrength = (pwd: string) => {
        let score = 0;
        const regexes = [
            /[a-z]/,
            /[A-Z]/,
            /[0-9]/,
            /[!@#$%^&*()_+\-=[\]{}|;':",.<>/?]/,
        ];
        regexes.forEach((regex) => {
            if (regex.test(pwd)) {
                score += 1;
            }
        });
        if (length >= 12 && score >= 4) {
            setStrength('Strong');
        } else if (length >= 8 && score >= 3) {
            setStrength('Medium');
        } else {
            setStrength('Weak');
        }
    };

    const copyToClipboard = () => {
        if (password) {
            navigator.clipboard.writeText(password);
            toast('Password copied to clipboard!');
        }
    };

    const getStrengthClass = (strength: string) => {
        switch (strength) {
            case 'Strong':
                return styles.strong;
            case 'Medium':
                return styles.medium;
            case 'Weak':
                return styles.weak;
            default:
                return '';
        }
    };

    return (
        <div className={styles.passwordGenerator}>
            {/* SEO Meta Tags */}
            <SEO
                title="Password Generator | CyberZero AI"
                description="Generate strong, secure passwords with CyberZero AI's free Password Generator tool. Enhance your cyber safety today."
                keywords="Password Generator, Secure Passwords, CyberZero AI, Cyber Security, Strong Passwords, Password Strength"
                author="Karthik Ramesh"
                image="/assets/password/generator/password-generator-banner.png"
                url="https://tools.cyberzero.ai/password/generate"
                ogTitle="Password Generator | CyberZero AI"
                ogDescription="Generate strong, secure passwords with CyberZero AI's free Password Generator tool."
                ogImage="/assets/password/generator/password-generator-banner.png"
                ogUrl="https://tools.cyberzero.ai/password/generate"
                twitterTitle="Password Generator | CyberZero AI"
                twitterDescription="Generate strong, secure passwords with CyberZero AI's free Password Generator tool."
                twitterImage="/assets/password/generator/password-generator-banner.png"
                canonical="https://tools.cyberzero.ai/password/generate"
                structuredData={JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebApplication",
                    "name": "Password Generator",
                    "url": "https://tools.cyberzero.ai/password/generate",
                    "description": "Generate strong, secure passwords with CyberZero AI's free Password Generator tool. Enhance your cyber safety today.",
                    "applicationCategory": "UtilityApplication",
                    "operatingSystem": "All",
                    "author": {
                        "@type": "Person",
                        "name": "Karthik Ramesh"
                    },
                    "image": "/assets/password/generator/password-generator-banner.png"
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#2C3E50' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="Password Generator"
                description="Create strong and secure passwords to enhance your cyber safety."
                imageUrl="/assets/password/generator/password-generator-banner.png"
                imageAlt="Password Generator"
            />

            {/* Password Generator Form */}
            <div className={styles.passwordGeneratorForm}>
                <div className={styles.output}>
                    <input type="text" value={password} readOnly />
                    <button onClick={generatePassword} title="Regenerate">
                        <FontAwesomeIcon icon={faArrowsRotate} />
                    </button>
                    <button onClick={copyToClipboard} title="Copy">
                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>

                <div className={styles.controls}>
                    <label htmlFor="length">Length: {length}</label>
                    <input
                        type="range"
                        id="length"
                        min="4"
                        max="64"
                        value={length}
                        onChange={(e) => setLength(Number(e.target.value))}
                    />
                </div>
                <div className={styles.options}>
                    <div>
                        <input
                            type="checkbox"
                            id="uppercase"
                            checked={includeUppercase}
                            onChange={(e) => setIncludeUppercase(e.target.checked)}
                        />
                        <label htmlFor="uppercase">Include Uppercase Letters</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="lowercase"
                            checked={includeLowercase}
                            onChange={(e) => setIncludeLowercase(e.target.checked)}
                        />
                        <label htmlFor="lowercase">Include Lowercase Letters</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="numbers"
                            checked={includeNumbers}
                            onChange={(e) => setIncludeNumbers(e.target.checked)}
                        />
                        <label htmlFor="numbers">Include Numbers</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="symbols"
                            checked={includeSymbols}
                            onChange={(e) => setIncludeSymbols(e.target.checked)}
                        />
                        <label htmlFor="symbols">Include Special Characters</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="avoidAmbiguous"
                            checked={avoidAmbiguous}
                            onChange={(e) => setAvoidAmbiguous(e.target.checked)}
                        />
                        <label htmlFor="avoidAmbiguous">Avoid Ambiguous Characters</label>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            id="excludeKeywordsOption"
                            checked={excludeKeywordsOption}
                            onChange={(e) => setExcludeKeywordsOption(e.target.checked)}
                        />
                        <label htmlFor="excludeKeywordsOption">Exclude Keywords</label>
                    </div>
                </div>

                {/* Show textarea if exclude keywords option is selected */}
                {excludeKeywordsOption && (
                    <div className={styles.inputGroup}>
                        <label htmlFor="excludeKeywords">Enter Keywords to Exclude (comma-separated):</label>
                        <textarea
                            id="excludeKeywords"
                            value={excludeKeywords}
                            onChange={(e) => setExcludeKeywords(e.target.value)}
                            placeholder="Enter keywords like your name, date of birth, etc."
                        ></textarea>
                    </div>
                )}

                <div className={`${styles.strength} ${getStrengthClass(strength)}`}>
                    Password Strength: {strength}
                </div>
            </div>
        </div>
    );
};