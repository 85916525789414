// src/pages/HomePage.tsx

import React from 'react';
import styles from '../styles/HomePage.module.css';
import { SEO } from '../components/SEO';

const HomePage: React.FC = () => {
    return (
      <div className={styles.homeContainer}>
        {/* SEO Meta Tags */}
        <SEO
          title="CyberZero AI | Free Cybersecurity and Developer Tools for Enhanced Security"
          description="CyberZero AI offers a comprehensive suite of free cybersecurity and developer tools designed to enhance security and efficiency. Protect your data and streamline your development processes with our professional tools."
          keywords="CyberZero AI, Cybersecurity Tools, Developer Tools, Security Tools, Free Online Tools, Encryption, Hashing, Password Generator, Data Protection, Cybersecurity Company, Custom Tools"
          author="CyberZero AI Team"
          image="/assets/home/banner.png"
          url="https://tools.cyberzero.ai/"
          ogTitle="CyberZero AI | Free Cybersecurity and Developer Tools"
          ogDescription="Enhance your security and development efficiency with CyberZero AI's suite of free cybersecurity and developer tools."
          ogImage="/assets/home/banner.png"
          ogUrl="https://tools.cyberzero.ai/"
          twitterTitle="CyberZero AI | Free Cybersecurity and Developer Tools"
          twitterDescription="Enhance your security and development efficiency with CyberZero AI's suite of free cybersecurity and developer tools."
          twitterImage="/assets/home/banner.png"
          canonical="https://tools.cyberzero.ai/"
          structuredData={JSON.stringify({
            "@context": "http://schema.org",
            "@type": "WebSite",
            "name": "CyberZero AI",
            "url": "https://tools.cyberzero.ai/",
            "description": "CyberZero AI offers a comprehensive suite of free cybersecurity and developer tools designed to enhance security and efficiency. Protect your data and streamline your development processes with our professional tools.",
            "author": {
              "@type": "Organization",
              "name": "CyberZero AI Team"
            },
            "image": "/assets/home/banner.png",
            "publisher": {
              "@type": "Organization",
              "name": "CyberZero AI",
              "logo": {
                "@type": "ImageObject",
                "url": "/assets/logo.png"
              }
            }
          })}
          meta={[
            { charset: 'utf-8' },
            { property: 'og:type', content: 'website' },
            { property: 'og:locale', content: 'en_US' },
            { name: 'twitter:card', content: 'summary_large_image' },
            { name: 'twitter:site', content: '@CyberZero.AI' },
            { name: 'twitter:creator', content: '@CyberZero.AI' },
            { name: 'viewport', content: 'width=device-width, initial-scale=1' },
            { name: 'robots', content: 'index, follow' },
            { name: 'theme-color', content: '#2C3E50' },
            { name: 'application-name', content: 'CyberZero AI Security Tools' },
            { name: 'apple-mobile-web-app-capable', content: 'yes' },
          ]}
        />

        {/* Hero Section */}
        <section className={styles.heroSection}>
          <div className={styles.heroContent}>
            <h1>Secure Your Digital World with CyberZero AI</h1>
            <p>
              Empowering developers and security professionals with a suite of
              free, powerful cybersecurity and developer tools.
            </p>
          </div>
          <div className={styles.heroImage}>
            <img src="/assets/home/hero-image.png" alt="CyberZero AI Tools" />
          </div>
        </section>

        {/* Why Choose CyberZero AI Section */}
        <section className={styles.whyChooseSection}>
          <h2>Why Choose CyberZero AI?</h2>
          <p>
            At CyberZero AI, we understand the challenges faced by developers
            and security professionals in today's digital landscape. Our tools
            are designed to provide you with the resources you need to protect
            your data, enhance your security posture, and streamline your
            development processes.
          </p>
          <div className={styles.benefitsGrid}>
            <div className={styles.benefitItem}>
              <img
                src="/assets/home/icons/comprehensive.png"
                alt="Comprehensive Tools"
              />
              <h3>Comprehensive Cybersecurity Tools</h3>
              <p>
                From password generation to encryption and hashing, our tools
                cover all aspects of cybersecurity to help you safeguard your
                data.
              </p>
            </div>
            <div className={styles.benefitItem}>
              <img
                src="/assets/home/icons/developer-friendly.png"
                alt="Developer Friendly"
              />
              <h3>Developer-Friendly Utilities</h3>
              <p>
                Optimize your workflow with our suite of developer tools,
                designed to increase productivity and efficiency.
              </p>
            </div>
            <div className={styles.benefitItem}>
              <img
                src="/assets/home/icons/free.png"
                alt="Free and Accessible"
              />
              <h3>Free and Accessible</h3>
              <p>
                All our tools are completely free to use, with no hidden costs
                or subscriptions. Access them anytime, anywhere.
              </p>
            </div>
            <div className={styles.benefitItem}>
              <img
                src="/assets/home/icons/professional.png"
                alt="Professional and Reliable"
              />
              <h3>Professional and Reliable</h3>
              <p>
                Developed by cybersecurity experts, our tools adhere to industry
                standards and best practices.
              </p>
            </div>
          </div>
        </section>

        {/* Our Tools and Services */}
        <section className={styles.toolsSection}>
          <h2>Our Tools and Services</h2>
          <div className={styles.toolsGrid}>
            {/* Password Generator */}
            <div className={styles.toolItem}>
              <img
                src="/assets/home/icons/password.png"
                alt="Password Generator"
              />
              <h3>Password Generator</h3>
              <p>
                Create strong, secure passwords to protect your accounts and
                data from unauthorized access.
              </p>
            </div>
            {/* Encryption Tools */}
            <div className={styles.toolItem}>
              <img
                src="/assets/home/icons/encryption.png"
                alt="Encryption Tools"
              />
              <h3>Encryption Tools</h3>
              <p>
                Secure your sensitive information with our advanced encryption
                utilities.
              </p>
            </div>
            {/* Hashing Tools */}
            <div className={styles.toolItem}>
              <img src="/assets/home/icons/hash.png" alt="Hashing Tools" />
              <h3>Hashing Tools</h3>
              <p>
                Verify data integrity and protect passwords with our reliable
                hashing algorithms.
              </p>
            </div>
            {/* JSON Formatter */}
            <div className={styles.toolItem}>
              <img src="/assets/home/icons/json.png" alt="JSON Formatter" />
              <h3>JSON Formatter</h3>
              <p>
                Easily format and beautify your JSON data for better readability
                and debugging.
              </p>
            </div>
            {/* Text Utilities */}
            <div className={styles.toolItem}>
              <img src="/assets/home/icons/text.png" alt="Text Utilities" />
              <h3>Text Utilities</h3>
              <p>
                Manipulate and analyze text data with our suite of text
                processing tools.
              </p>
            </div>
            {/* Custom Tools */}
            <div className={styles.toolItem}>
              <img src="/assets/home/icons/custom.png" alt="Custom Tools" />
              <h3>Need Custom Tools?</h3>
              <p>
                Looking for a specific tool? We can help develop custom tools
                tailored to your needs.
              </p>
            </div>
          </div>
        </section>

        {/* About Us Section */}
        <section className={styles.aboutSection}>
          <h2>About CyberZero AI</h2>
          <p>
            CyberZero AI is a cybersecurity product-based company dedicated to
            providing free, high-quality tools to the community. Our mission is
            to empower developers and security professionals by offering tools
            that enhance security, productivity, and efficiency.
          </p>
          <p>
            Our team of experts continuously works to develop innovative
            solutions that address the evolving challenges in cybersecurity and
            software development. We believe in open access to essential tools
            that help safeguard digital assets and streamline development
            processes.
          </p>
        </section>

        {/* Custom Tools Section */}
        <section className={styles.customToolsSection}>
          <h2>Need Custom Tools?</h2>
          <p>
            Can't find the tool you're looking for? CyberZero AI offers custom
            tool development to meet your specific requirements. Whether it's a
            unique encryption method, a specialized data formatter, or any other
            utility, we're here to help.
          </p>
          <p>
            Contact us at{' '}
            <a href="mailto:karthik@cyberzero.dev">karthik@cyberzero.dev</a> to
            discuss your needs.
          </p>
        </section>
      </div>
    );
};

export default HomePage;
