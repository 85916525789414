// src/tools/Text/Base64/index.tsx

import React, { useState, useRef, useEffect } from 'react';
import styles from './TextBase64.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faExpand,
    faCompress,
    faQuestionCircle, faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import * as iconv from 'iconv-lite-umd'; // Importing iconv-lite-umd
import { Buffer } from 'buffer';
import {Hero} from "../../../components/Hero";
import {SEO} from "../../../components/SEO"; // Importing Buffer


declare global {
    interface Window {
        Buffer: typeof Buffer;
    }
}

export const TextBase64: React.FC = () => {
    const [inputText, setInputText] = useState('');
    const [outputText, setOutputText] = useState('');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [destinationCharset, setDestinationCharset] = useState('utf-8');
    const [newlineSeparator, setNewlineSeparator] = useState('LF');
    const [encodeEachLineSeparately, setEncodeEachLineSeparately] = useState(false);
    const [splitLinesIntoChunks, setSplitLinesIntoChunks] = useState(false);
    const [performUrlSafeEncoding, setPerformUrlSafeEncoding] = useState(false);
    const [encodeInRealTime, setEncodeInRealTime] = useState(false);
    const outputRef = useRef<HTMLDivElement>(null);
    const [isDragOver, setIsDragOver] = useState(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [lastOperation, setLastOperation] = useState<'encode' | 'decode'>('encode');


    window.Buffer = Buffer;

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setInputText(e.target.value);
        if (encodeInRealTime) {
            performOperation('encode'); // Always encode in real-time
        }
    };

    const uint8ArrayToBase64 = (bytes: Uint8Array): string => {
        let binary = '';
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return btoa(binary);
    };


    const performOperation = (operation: 'encode' | 'decode') => {
        try {
            let result;
            if (encodeEachLineSeparately) {
                const lines = inputText.split(/\r?\n/);
                const processedLines = lines.map((line) => {
                    if (operation === 'encode') {
                        return encodeLine(line);
                    } else {
                        return decodeLine(line);
                    }
                });
                result = processedLines.join(getNewlineCharacter());
            } else {
                if (operation === 'encode') {
                    result = encodeLine(inputText);
                } else {
                    result = decodeLine(inputText);
                }
            }
            setOutputText(result);
        } catch (e: unknown) {
            setOutputText('');
            if (e instanceof Error) {
                if (operation === 'decode') {
                    toast.error('Invalid Base64 input. Decoding failed.');
                } else {
                    toast.error('Encoding failed. Please check your input.');
                }
            } else {
                toast.error('An unknown error occurred during processing.');
            }
        }
    };

    const encodeLine = (line: string) => {
        const buffer = iconv.encode(line, destinationCharset);
        const base64String = uint8ArrayToBase64(buffer);

        let finalString = base64String;
        if (performUrlSafeEncoding) {
            finalString = base64String
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=+$/, '');
        }
        if (splitLinesIntoChunks) {
            finalString =
                finalString.match(/.{1,76}/g)?.join(getNewlineCharacter()) || finalString;
        }
        return finalString;
    };

    const decodeLine = (line: string) => {
        let base64String = line;
        if (performUrlSafeEncoding) {
            base64String = base64String.replace(/-/g, '+').replace(/_/g, '/');
            while (base64String.length % 4) {
                base64String += '=';
            }
        }
        const binaryString = atob(base64String);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return iconv.decode(bytes, destinationCharset);
    };

    const getNewlineCharacter = () => {
        switch (newlineSeparator) {
            case 'CRLF':
                return '\r\n';
            case 'CR':
                return '\r';
            case 'LF':
            default:
                return '\n';
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(outputText).then(() => {
            toast('Output copied to clipboard!');
        });
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (outputRef.current) {
                outputRef.current.requestFullscreen().catch((err) => {
                    toast.error(
                        `Error attempting to enable full-screen mode: ${err.message}`
                    );
                });
                setIsFullScreen(true);
            }
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    };

    const handleFileUpload = (file: File | undefined) => {
        if (file) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setInputText(event.target?.result as string);
                if (encodeInRealTime) {
                    performOperation(lastOperation);
                }
            };
            reader.readAsText(file);
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullScreenChange
            );
        };
    }, []);

    return (
        <div className={styles.base64EncoderDecoder}>
            {/* SEO Meta Tags */}
            <SEO
                title="Base64 Encoder/Decoder | CyberZero AI"
                description="Encode and decode Base64 data with CyberZero AI's free Base64 Encoder/Decoder tool."
                keywords="Base64 Encoder, Base64 Decoder, CyberZero AI, Developer Tools, Encode Base64, Decode Base64"
                author="Karthik Ramesh"
                image="/assets/text/base64/base64-encoder-decoder-banner.png"
                url="https://tools.cyberzero.ai/text/base64"
                ogTitle="Base64 Encoder/Decoder | CyberZero AI"
                ogDescription="Encode and decode Base64 data with CyberZero AI's free Base64 Encoder/Decoder tool."
                ogImage="/assets/text/base64/base64-encoder-decoder-banner.png"
                ogUrl="https://tools.cyberzero.ai/text/base64"
                twitterTitle="Base64 Encoder/Decoder | CyberZero AI"
                twitterDescription="Encode and decode Base64 data with CyberZero AI's free Base64 Encoder/Decoder tool."
                twitterImage="/assets/text/base64/base64-encoder-decoder-banner.png"
                canonical="https://tools.cyberzero.ai/text/base64"
                structuredData={JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebApplication",
                    "name": "Base64 Encoder/Decoder",
                    "url": "https://tools.cyberzero.ai/text/base64",
                    "description": "Encode and decode Base64 data with CyberZero AI's free Base64 Encoder/Decoder tool.",
                    "applicationCategory": "UtilityApplication",
                    "operatingSystem": "All",
                    "author": {
                        "@type": "Person",
                        "name": "Karthik Ramesh"
                    },
                    "image": "/assets/text/base64/base64-encoder-decoder-banner.png",
                    "publisher": {
                        "@type": "Organization",
                        "name": "CyberZero AI",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "/assets/logo.png"
                        }
                    }
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#2C3E50' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="Base64 Encoder/Decoder"
                description="Encode and decode Base64 data effortlessly."
                imageUrl="/assets/text/base64/base64-encoder-decoder-banner.png"
                imageAlt="Base64 Encoder/Decoder"
            />

            {/* Base64 Encoder/Decoder Form */}
            <div className={styles.formContainer}>
                {/* Input Area */}
                <textarea
                    className={styles.inputArea}
                    value={inputText}
                    onChange={handleInputChange}
                    placeholder="Enter text here"
                ></textarea>

                {/* Options */}
                <div className={styles.options}>
                    {/* Destination Character Set */}
                    <div className={styles.optionGroup}>
                        <label htmlFor="destinationCharset">
                            Destination character set{' '}
                            <span className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span className={styles.tooltipText}>
                  The character set to use for encoding/decoding.
                </span>
              </span>
                        </label>
                        <select
                            id="destinationCharset"
                            value={destinationCharset}
                            onChange={(e) => setDestinationCharset(e.target.value)}
                        >
                            <option value="utf-8">UTF-8</option>
                            <option value="utf-16le">UTF-16LE</option>
                            <option value="utf-16be">UTF-16BE</option>
                            <option value="windows-1252">Windows-1252</option>
                            <option value="iso-8859-1">ISO-8859-1</option>
                            {/* Add other character sets as needed */}
                        </select>
                    </div>

                    {/* Destination Newline Separator */}
                    <div className={styles.optionGroup}>
                        <label htmlFor="newlineSeparator">
                            Destination newline separator{' '}
                            <span className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span className={styles.tooltipText}>
                  The newline character(s) to use in the output.
                </span>
              </span>
                        </label>
                        <select
                            id="newlineSeparator"
                            value={newlineSeparator}
                            onChange={(e) => setNewlineSeparator(e.target.value)}
                        >
                            <option value="LF">LF (Unix)</option>
                            <option value="CRLF">CRLF (Windows)</option>
                            <option value="CR">CR (Mac)</option>
                        </select>
                    </div>

                    {/* Encode/Decode Each Line Separately */}
                    <div className={styles.optionGroup}>
                        <label>
                            <input
                                type="checkbox"
                                checked={encodeEachLineSeparately}
                                onChange={(e) => setEncodeEachLineSeparately(e.target.checked)}
                            />{' '}
                            Encode/Decode each line separately{' '}
                            <span className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span className={styles.tooltipText}>
                  Useful when you have multiple entries to encode/decode.
                </span>
              </span>
                        </label>
                    </div>

                    {/* Split Lines into Chunks */}
                    <div className={styles.optionGroup}>
                        <label>
                            <input
                                type="checkbox"
                                checked={splitLinesIntoChunks}
                                onChange={(e) => setSplitLinesIntoChunks(e.target.checked)}
                            />{' '}
                            Split lines into 76 character wide chunks{' '}
                            <span className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span className={styles.tooltipText}>
                  Useful for MIME encoding.
                </span>
              </span>
                        </label>
                    </div>

                    {/* Perform URL-Safe Encoding */}
                    <div className={styles.optionGroup}>
                        <label>
                            <input
                                type="checkbox"
                                checked={performUrlSafeEncoding}
                                onChange={(e) => setPerformUrlSafeEncoding(e.target.checked)}
                            />{' '}
                            Perform URL-safe encoding{' '}
                            <span className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle} />
                <span className={styles.tooltipText}>
                  Uses Base64URL format for safe URL encoding.
                </span>
              </span>
                        </label>
                    </div>

                    {/* Encode in Real-Time */}
                    <div className={styles.optionGroup}>
                        <label>
                            Encodes in real-time as you type or paste (encoding only){' '}
                            <span className={styles.tooltip}>
      <FontAwesomeIcon icon={faQuestionCircle}/>
      <span className={styles.tooltipText}>
        Automatically encodes as you type or paste text. Only supports encoding.
      </span>
    </span>
                        </label>
                        <div className={styles.toggleSwitch}>
                            <input
                                type="checkbox"
                                id="encodeInRealTime"
                                checked={encodeInRealTime}
                                onChange={(e) => setEncodeInRealTime(e.target.checked)}
                            />
                            <label htmlFor="encodeInRealTime"></label>
                        </div>
                    </div>

                    {/* Upload File */}
                    <div className={styles.optionGroup}>
                        <label>
                            Upload file{' '}
                            <span className={styles.tooltip}>
                <FontAwesomeIcon icon={faQuestionCircle}/>
                <span className={styles.tooltipText}>
                  Load input text from a file.
                </span>
              </span>
                        </label>
                        <div
                            className={`${styles.uploadArea} ${
                                isDragOver ? styles.dragOver : ''
                            }`}
                            onClick={() => fileInputRef.current?.click()}
                            onDragOver={(e) => {
                                e.preventDefault();
                                setIsDragOver(true);
                            }}
                            onDragLeave={(e) => {
                                e.preventDefault();
                                setIsDragOver(false);
                            }}
                            onDrop={(e) => {
                                e.preventDefault();
                                setIsDragOver(false);
                                const file = e.dataTransfer.files[0];
                                handleFileUpload(file);
                            }}
                        >
                            <div className={styles.iconContainer}>
                                <FontAwesomeIcon icon={faUpload} size="4x"/>
                            </div>
                            <p>Drag and drop a file here or click to upload</p>
                        </div>
                        <input
                            type="file"
                            ref={fileInputRef}
                            style={{display: 'none'}}
                            onChange={(e) => {
                                const file = e.target.files?.[0];
                                handleFileUpload(file);
                            }}
                        />
                    </div>
                </div>

                {/* Action Buttons */}
                <div className={styles.actionButtons}>
                    <button
                        className={styles.encodeButton}
                        onClick={() => {
                            setLastOperation('encode');
                            performOperation('encode');
                        }}
                    >
                        Encode
                    </button>
                    <button
                        className={styles.decodeButton}
                        onClick={() => {
                            setLastOperation('decode');
                            performOperation('decode');
                        }}
                    >
                        Decode
                    </button>
                </div>

                {/* Output Window */}
                {outputText && (
                    <div
                        className={`${styles.output} ${
                            isFullScreen ? styles.fullScreen : ''
                        }`}
                        ref={outputRef}
                    >
                        {/* Output Buttons */}
                        <div className={styles.outputButtons}>
                            <button onClick={copyToClipboard} title="Copy">
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button onClick={toggleFullScreen} title="Full Screen">
                                <FontAwesomeIcon
                                    icon={isFullScreen ? faCompress : faExpand}
                                />
                            </button>
                        </div>

                        {/* Output Area */}
                        <div className={styles.outputArea}>
                            <pre className={styles.outputContent}>{outputText}</pre>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};