import React, { useState, useEffect } from 'react';
import styles from './JwtDecoder.module.css';
import { toast } from 'react-toastify';
import { Hero } from '../../../components/Hero';
import { SEO } from '../../../components/SEO';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCopy } from '@fortawesome/free-solid-svg-icons';

interface DecodedJWT {
  [key: string]: unknown;
}

const convertEpochToLocalTime = (epoch: number): string => {
  const date = new Date(epoch * 1000); // Convert seconds to milliseconds
  return date.toLocaleString();
};


const isEpochTime = (value: number): boolean => {
  // Check if the value is a valid epoch time (e.g., within a reasonable range)
  return value > 1000000000 && value < 2000000000; // Between ~2001 and ~2033
};

const formatEpochTimes = (data: DecodedJWT): DecodedJWT => {
  const formattedData: DecodedJWT = {};

  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const value = data[key];
      if (typeof value === 'number' && isEpochTime(value)) {
        // Keep the original number for calculation and append the local time as a display string
        formattedData[key] = `${value} (${convertEpochToLocalTime(value)})`; // Add display value
      } else if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
        // Recursively format nested objects
        formattedData[key] = formatEpochTimes(value as DecodedJWT);
      } else {
        formattedData[key] = value;
      }
    }
  }

  return formattedData;
};



const copyToClipboard = (content: string) => {
  navigator.clipboard.writeText(content).then(() => {
    toast.success('Copied to clipboard!');
  });
};

const JWTDecoder: React.FC = () => {
  const [inputJWT, setInputJWT] = useState('');
  const [header, setHeader] = useState<DecodedJWT | null>(null);
  const [payload, setPayload] = useState<DecodedJWT | null>(null);
  const [signature, setSignature] = useState<string | null>(null);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean | null>(null);

  useEffect(() => {
    if (payload && typeof payload === 'object') {
      if ('exp' in payload) {
        const expString = payload.exp as string;
        const exp = Number(expString.split(" ")[0]); // Ensure `exp` is used as a number

        if (isNaN(exp)) {
          setStatusMessage('Invalid expiration time');
          setIsValid(false);
          return;
        }

        const interval = setInterval(() => {
          const currentTime = Math.floor(Date.now() / 1000);
          const timeLeft = exp - currentTime;

          if (timeLeft > 0) {
            const hours = Math.floor(timeLeft / 3600);
            const minutes = Math.floor((timeLeft % 3600) / 60);
            const seconds = timeLeft % 60;
            setStatusMessage(`Valid for another ${hours}h ${minutes}m ${seconds}s`);
            setIsValid(true);
          } else {
            const timeExpired = Math.abs(timeLeft);
            const expiredHours = Math.floor(timeExpired / 3600);
            const expiredMinutes = Math.floor((timeExpired % 3600) / 60);
            const expiredSeconds = timeExpired % 60;
            setStatusMessage(`Expired ${expiredHours}h ${expiredMinutes}m ${expiredSeconds}s ago`);
            setIsValid(false);
          }
        }, 1000);

        return () => clearInterval(interval);
      } else {
        setStatusMessage('Valid indefinitely');
        setIsValid(true);
      }
    }
  }, [payload]);

  const decodeJWT = () => {
    try {
      const [encodedHeader, encodedPayload, signature] = inputJWT.split('.');

      if (!encodedHeader || !encodedPayload || !signature) {
        setHeader(null);
        setPayload(null);
        setSignature(null);
        toast.error('Invalid JWT format.');
        return;
      }

      const decodedHeader = JSON.parse(atob(encodedHeader));
      const decodedPayload = JSON.parse(atob(encodedPayload));

      // Format epoch times in the payload and header to show local times
      const formattedHeader = formatEpochTimes(decodedHeader);
      const formattedPayload = formatEpochTimes(decodedPayload);

      setHeader(formattedHeader);
      setPayload(formattedPayload);
      setSignature(signature);
    } catch {
      setHeader(null);
      setPayload(null);
      setSignature(null);
      toast.error('Invalid JWT format.');
    }
  };

  const renderBlock = (title: string, content: string | DecodedJWT, colorClass: string) => (
    <div className={`${styles.jwtBlock} ${colorClass}`}>
      <div className={styles.blockHeader}>
        <h2>{title}</h2>
        <button
          className={styles.copyButton}
          onClick={() => copyToClipboard(typeof content === 'string' ? content : JSON.stringify(content, null, 2))}
        >
          <FontAwesomeIcon icon={faCopy} />
        </button>
      </div>
      <pre>{typeof content === 'string' ? content : JSON.stringify(content, null, 2)}</pre>
    </div>
  );

  return (
    <div className={styles.jwtDecoder}>
      <SEO
        title="JWT Decoder | CyberZero AI"
        description="Decode and view your JWT details with CyberZero AI's JWT Decoder tool."
        keywords="JWT Decoder, JWT Decoder, CyberZero AI, Token Analysis"
        author="Karthik Ramesh"
        image="/assets/jwt/decoder/jwt-decoder-banner.png"
        url="https://tools.cyberzero.ai/jwt/decoder"
        ogTitle="JWT Decoder | CyberZero AI"
        ogDescription="Decode and view your JWT details with CyberZero AI's JWT Decoder tool."
        ogImage="/assets/jwt/decoder/jwt-diewer-banner.png"
        ogUrl="https://tools.cyberzero.ai/jwt/decoder"
        twitterTitle="JWT Decoder | CyberZero AI"
        twitterDescription="Decode and view your JWT details with CyberZero AI's JWT Decoder tool."
        twitterImage="/assets/jwt/decoder/jwt-diewer-banner.png"
        canonical="https://tools.cyberzero.ai/jwt/decoder"
      />

      <Hero
        title="JWT Decoder"
        description="Decode and analyze your JWT easily."
        imageUrl="/assets/jwt/decoder/jwt-decoder-banner.png"
        imageAlt="JWT Decoder"
      />

      <div className={styles.jwtDecoder}>
        <textarea
          className={styles.inputArea}
          value={inputJWT}
          onChange={(e) => setInputJWT(e.target.value)}
          placeholder="Enter JWT here"
        ></textarea>
        <button className={styles.decodeButton} onClick={decodeJWT}>
          Decode JWT
        </button>

        {statusMessage && (
          <div className={`${styles.jwtBlock} ${isValid === false ? styles.statusExpiredBlock : styles.statusValidBlock}`}>
            <div className={styles.blockHeader}>
              <h2>Status</h2>
              {isValid === null && <FontAwesomeIcon icon={faExclamationTriangle} className={styles.dangerIcon} />}
            </div>
            <p>{statusMessage}</p>
          </div>
        )}

        {header && renderBlock('HEADER: ALGORITHM & TOKEN TYPE', header, styles.headerBlock)}
        {payload && renderBlock('PAYLOAD: DATA', payload, styles.payloadBlock)}
        {signature && renderBlock('VERIFY SIGNATURE', signature, styles.signatureBlock)}
      </div>
    </div>
  );
};

export default JWTDecoder;
