// src/tools/Decoder/JSONViewer/index.tsx
import React, { useState, useRef, useEffect } from 'react';
import styles from './JsonViewer.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCopy,
    faCode,
    faExpand,
    faCompress,
    faPlus,
    faMinus,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { CollapsibleJSON } from './CollapsibleJson';
import {Hero} from "../../../components/Hero";
import {SEO} from "../../../components/SEO";

type JSONValue =
    | string
    | number
    | boolean
    | null
    | { [key: string]: JSONValue }
    | JSONValue[];

type ExpandCollapseAction = {
    action: 'expand' | 'collapse';
    id: number;
};

export const JSONViewer: React.FC = () => {
    const [inputJSON, setInputJSON] = useState('');
    const [parsedJSON, setParsedJSON] = useState<JSONValue | null>(null);
    const [errorMessage, setErrorMessage] = useState('');
    const [expandAll, setExpandAll] = useState<ExpandCollapseAction | null>(null);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const outputRef = useRef<HTMLDivElement>(null);

    const formatJSON = () => {
        try {
            const parsed = JSON.parse(inputJSON);
            setParsedJSON(parsed);
            setErrorMessage('');
            setExpandAll({ action: 'collapse', id: Date.now() }); // Default to collapsed state
        } catch (e) {
            setParsedJSON(null);
            if (e instanceof SyntaxError) {
                setErrorMessage(e.message);
                toast.error(`JSON Parse Error: ${e.message}`);
            } else {
                setErrorMessage('An unknown error occurred.');
                toast.error('An unknown error occurred.');
            }
        }
    };

    const copyToClipboard = () => {
        if (parsedJSON) {
            const formattedText = JSON.stringify(parsedJSON, null, 2);
            navigator.clipboard.writeText(formattedText).then(() => {
                toast('JSON copied to clipboard!');
            });
        }
    };

    const handleExpandAll = () => {
        setExpandAll({ action: 'expand', id: Date.now() });
    };

    const handleCollapseAll = () => {
        setExpandAll({ action: 'collapse', id: Date.now() });
    };

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (outputRef.current) {
                outputRef.current.requestFullscreen().catch((err) => {
                    toast.error(`Error attempting to enable full-screen mode: ${err.message}`);
                });
                setIsFullScreen(true);
            }
        } else {
            if (document.fullscreenElement) {
                document.exitFullscreen();
                setIsFullScreen(false);
            }
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (!document.fullscreenElement) {
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
        };
    }, []);

    return (
        <div className={styles.jsonViewer}>
            {/* SEO Meta Tags */}
            <SEO
                title="JSON Viewer | CyberZero AI"
                description="View and analyze your JSON data with CyberZero AI's free JSON Viewer tool."
                keywords="JSON Viewer, JSON Analyzer, CyberZero AI, Developer Tools, View JSON, JSON Parser"
                author="Karthik Ramesh"
                image="/assets/json/viewer/json-viewer-banner.png"
                url="https://tools.cyberzero.ai/json/viewer"
                ogTitle="JSON Viewer | CyberZero AI"
                ogDescription="View and analyze your JSON data with CyberZero AI's free JSON Viewer tool."
                ogImage="/assets/json/viewer/json-viewer-banner.png"
                ogUrl="https://tools.cyberzero.ai/json/viewer"
                twitterTitle="JSON Viewer | CyberZero AI"
                twitterDescription="View and analyze your JSON data with CyberZero AI's free JSON Viewer tool."
                twitterImage="/assets/json/viewer/json-viewer-banner.png"
                canonical="https://tools.cyberzero.ai/json/viewer"
                structuredData={JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebApplication",
                    "name": "JSON Decoder",
                    "url": "https://tools.cyberzero.ai/json/viewer",
                    "description": "View and analyze your JSON data with CyberZero AI's free JSON Decoder tool.",
                    "applicationCategory": "DeveloperApplication",
                    "operatingSystem": "All",
                    "author": {
                        "@type": "Person",
                        "name": "Karthik Ramesh"
                    },
                    "image": "/assets/json/decoder/json-decoder-banner.png"
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#2C3E50' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="JSON Viewer"
                description="View and analyze your JSON data effortlessly."
                imageUrl="/assets/json/viewer/json-viewer-banner.png"
                imageAlt="JSON Viewer"
            />

            {/* JSON Decoder Form */}
            <div className={styles.jsonViewerForm}>
                {/* Input Area */}
                <textarea
                    className={styles.inputArea}
                    value={inputJSON}
                    onChange={(e) => setInputJSON(e.target.value)}
                    placeholder="Enter JSON here"
                ></textarea>
                <button className={styles.viewButton} onClick={formatJSON}>
                    <FontAwesomeIcon icon={faCode} /> View JSON
                </button>

                {errorMessage && (
                    <div className={styles.errorMessage}>
                        <p>Error: {errorMessage}</p>
                    </div>
                )}

                {parsedJSON && (
                    <div
                        className={`${styles.output} ${isFullScreen ? styles.fullScreen : ''}`}
                        ref={outputRef}
                    >
                        {/* Buttons */}
                        <div className={styles.outputButtons}>
                            <button onClick={copyToClipboard} title="Copy">
                                <FontAwesomeIcon icon={faCopy} />
                            </button>
                            <button onClick={handleExpandAll} title="Expand All">
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                            <button onClick={handleCollapseAll} title="Collapse All">
                                <FontAwesomeIcon icon={faMinus} />
                            </button>
                            <button onClick={toggleFullScreen} title="Full Screen">
                                <FontAwesomeIcon icon={isFullScreen ? faCompress : faExpand} />
                            </button>
                        </div>

                        {/* Output Area */}
                        <div className={styles.outputArea}>
                            <div className={styles.outputContent}>
                                <CollapsibleJSON
                                    data={parsedJSON}
                                    expandAll={expandAll}
                                    lineNumbers={true}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
