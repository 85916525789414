import React from 'react';
import { Link } from 'react-router-dom';
import styles from '../styles/NotFoundPage.module.css';
import { Helmet } from 'react-helmet';

const NotFoundPage: React.FC = () => {
    return (
        <div className={styles.notFoundContainer}>
            {/* SEO Meta Tags */}
            <Helmet>
                <title>404 - Page Not Found | CyberZero AI</title>
                <meta
                    name="description"
                    content="The page you're looking for doesn't exist. Explore CyberZero AI's free cybersecurity and developer tools to enhance your cyber safety."
                />
                <meta name="robots" content="noindex, follow" />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content="404 - Page Not Found | CyberZero AI" />
                <meta
                    property="og:description"
                    content="The page you're looking for doesn't exist. Discover free cybersecurity and developer tools at CyberZero AI."
                />
                <meta property="og:image" content="/assets/not-found/404-image.png" />
                <meta property="og:url" content="https://tools.cyberzero.ai/404" />
                {/* Twitter Card Meta Tags */}
                <meta name="twitter:title" content="404 - Page Not Found | CyberZero AI" />
                <meta
                    name="twitter:description"
                    content="The page you're looking for doesn't exist. Discover free cybersecurity and developer tools at CyberZero AI."
                />
                <meta name="twitter:image" content="/assets/not-found/404-image.png" />
            </Helmet>

            <div className={styles.imageContainer}>
                <img src="/assets/not-found/404-image.png" alt="Page Not Found" className={styles.notFoundImage} />
            </div>
            <h2>Oops! Page Not Found</h2>
            <p>The page you're looking for doesn't exist or has been moved.</p>
            <Link to="/" className={styles.homeLink}>
                Return to Home
            </Link>
        </div>
    );
};

export default NotFoundPage;
