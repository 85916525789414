import React from 'react';
import { Link, useParams } from 'react-router-dom';
import styles from '../styles/CategoryPage.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryToolList } from '../utils/CategoryToolList';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Helmet } from 'react-helmet';
import NotFoundPage from "./NotFoundPage";

interface Tool {
    name: string;
    path: string;
    icon: IconDefinition;
}

interface Category {
    name: string;
    path: string;
    icon: IconDefinition;
    tools: Tool[];
}

const CategoryPage: React.FC = () => {
    const { category } = useParams<{ category: string }>();
    const ListApp: Category = CategoryToolList[category || ''];

    if (!ListApp) {
        return <NotFoundPage />;
    }

    return (
        <div>
            <Helmet>
                <title>{`${ListApp.name} Tools | CyberZero AI`}</title>
                <meta
                    name="description"
                    content={`Explore a variety of ${ListApp.name.toLowerCase()} tools offered by CyberZero AI to enhance your cybersecurity and development efficiency.`}
                />
                <meta
                    name="keywords"
                    content={`${ListApp.name} tools, CyberZero AI, ${ListApp.name.toLowerCase()} utilities, cybersecurity tools, developer tools`}
                />
                {/* Open Graph Meta Tags */}
                <meta property="og:title" content={`${ListApp.name} Tools | CyberZero AI`} />
                <meta
                    property="og:description"
                    content={`Discover ${ListApp.name.toLowerCase()} tools to improve your workflow and security.`}
                />
                <meta property="og:url" content={`https://tools.cyberzero.ai${ListApp.path}`} />
                {/* Twitter Meta Tags */}
                <meta name="twitter:title" content={`${ListApp.name} Tools | CyberZero AI`} />
                <meta
                    name="twitter:description"
                    content={`Discover ${ListApp.name.toLowerCase()} tools to improve your workflow and security.`}
                />
            </Helmet>

            <h2>{ListApp.name} Tools</h2>
            <div className={styles.tileContainer}>
                {ListApp.tools.map((app: Tool) => (
                    <Link key={app.name} to={app.path} className={styles.tile}>
                        <FontAwesomeIcon icon={app.icon} className={styles.tileIcon} />
                        <span className={styles.tileText}>{app.name}</span>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default CategoryPage;