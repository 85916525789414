import React, { useState, useEffect } from 'react';
import styles from './PasswordStrengthChecker.module.css';
import { CommonPasswords } from '../../../utils/CommonPasswords';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {Hero} from "../../../components/Hero";
import {SEO} from "../../../components/SEO";

const PasswordStrengthChecker: React.FC = () => {
    const [password, setPassword] = useState<string>('');
    const [keywords, setKeywords] = useState<string>('');
    const [strength, setStrength] = useState<string>('');

    useEffect(() => {
        evaluateStrength(password); // Evaluate strength on page load
    }, [password]);

    const evaluateStrength = (pwd: string) => {
        let score = 0;

        if (pwd.length === 0) {
            setStrength("Enter your password to Evaluate the Password Strength")
            return;
        }

        // Criteria for password strength
        const lengthCriteria = pwd.length >= 8;
        const upperCaseCriteria = /[A-Z]/.test(pwd);
        const lowerCaseCriteria = /[a-z]/.test(pwd);
        const numberCriteria = /[0-9]/.test(pwd);
        const symbolCriteria = /[!@#$%^&*()_+\-=[\]{}|;':",.<>/?]/.test(pwd);

        if (lengthCriteria) score += 1;
        if (upperCaseCriteria) score += 1;
        if (lowerCaseCriteria) score += 1;
        if (numberCriteria) score += 1;
        if (symbolCriteria) score += 1;

        // Check if password contains any of the keywords
        const keywordList = keywords
            .split(',')
            .map((k) => k.trim().toLowerCase())
            .filter((k) => k.length > 0);

        let containsKeyword = false;
        for (const keyword of keywordList) {
            if (pwd.toLowerCase().includes(keyword)) {
                containsKeyword = true;
                break;
            }
        }

        // Check if password matches any common passwords
        const isCommonPassword = CommonPasswords.some(
            (commonPwd) => commonPwd.toLowerCase() === pwd.toLowerCase() ||
                commonPwd.toUpperCase() === pwd.toUpperCase() ||
                commonPwd === pwd ||
                pwd.indexOf(commonPwd) !== -1 ||
                pwd.indexOf(commonPwd.toLowerCase()) !== -1 ||
                pwd.indexOf(commonPwd.toUpperCase()) !== -1 ||
                pwd.toLowerCase().indexOf(commonPwd) !== -1 ||
                pwd.toUpperCase().indexOf(commonPwd) !== -1 ||
                pwd.toLowerCase().indexOf(commonPwd.toLowerCase()) !== -1 ||
                pwd.toUpperCase().indexOf(commonPwd.toUpperCase()) !== -1 ||
                commonPwd.indexOf(pwd) !== -1 ||
                commonPwd.indexOf(pwd.toLowerCase()) !== -1 ||
                commonPwd.indexOf(pwd.toUpperCase()) !== -1 ||
                commonPwd.toLowerCase().indexOf(pwd) !== -1 ||
                commonPwd.toUpperCase().indexOf(pwd) !== -1 ||
                commonPwd.toLowerCase().indexOf(pwd.toLowerCase()) !== -1 ||
                commonPwd.toUpperCase().indexOf(pwd.toUpperCase()) !== -1
        );

        if (containsKeyword) {
            score -= 2; // Penalize for including keywords
        }

        if (isCommonPassword) {
            score -= 3; // Heavily penalize for using a common password
        }

        // Ensure score is within 0 to 5
        if (score < 0) score = 0;
        if (score > 5) score = 5;

        // Set strength based on score
        if (score >= 4) {
            setStrength('Strong');
        } else if (score >= 2) {
            setStrength('Medium');
        } else {
            setStrength('Weak');
        }

        // Debugging statements
        console.log('Password:', pwd);
        console.log('Lowercase Password:', pwd.toLowerCase());
        console.log('Is Common Password:', isCommonPassword);
        console.log('Score:', score);
        console.log('Strength:', strength);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const pwd = e.target.value;
        setPassword(pwd);
        evaluateStrength(pwd);
    };

    const handleKeywordsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setKeywords(e.target.value);
        evaluateStrength(password);
    };

    const getStrengthClass = (strength: string) => {
        switch (strength) {
            case 'Strong':
                return styles.strong;
            case 'Medium':
                return styles.medium;
            case 'Weak':
                return styles.weak;
            default:
                return '';
        }
    };

    const copyToClipboard = () => {
        if (password) {
            navigator.clipboard.writeText(password);
            toast('Password copied to clipboard!');
        }
    };

    return (
        <div className={styles.passwordStrengthChecker}>
            {/* SEO Meta Tags */}
            <SEO
                title="Password Strength Checker | CyberZero AI"
                description="Check the strength of your passwords with CyberZero AI's free Password Strength Checker tool. Enhance your cyber safety by ensuring your passwords are secure."
                keywords="Password Strength Checker, Password Security, CyberZero AI, Cyber Safety, Password Analysis, Strong Passwords"
                author="Karthik Ramesh"
                image="/assets/password/strength-checker/password-strength-checker-banner.png"
                url="https://tools.cyberzero.ai/password/strength-checker"
                ogTitle="Password Strength Checker | CyberZero AI"
                ogDescription="Check the strength of your passwords with CyberZero AI's free Password Strength Checker tool."
                ogImage="/assets/password/strength-checker/password-strength-checker-banner.png"
                ogUrl="https://tools.cyberzero.ai/password/strength-checker"
                twitterTitle="Password Strength Checker | CyberZero AI"
                twitterDescription="Check the strength of your passwords with CyberZero AI's free Password Strength Checker tool."
                twitterImage="/assets/password/strength-checker/password-strength-checker-banner.png"
                canonical="https://tools.cyberzero.ai/password/strength-checker"
                structuredData={JSON.stringify({
                    "@context": "http://schema.org",
                    "@type": "WebApplication",
                    "name": "Password Strength Checker",
                    "url": "https://tools.cyberzero.ai/password/strength-checker",
                    "description": "Check the strength of your passwords with CyberZero AI's free Password Strength Checker tool. Enhance your cyber safety by ensuring your passwords are secure.",
                    "applicationCategory": "UtilityApplication",
                    "operatingSystem": "All",
                    "author": {
                        "@type": "Person",
                        "name": "Karthik Ramesh"
                    },
                    "image": "/assets/password/strength-checker/password-strength-checker-banner.png",
                    "publisher": {
                        "@type": "Organization",
                        "name": "CyberZero AI",
                        "logo": {
                            "@type": "ImageObject",
                            "url": "/assets/logo.png"
                        }
                    }
                })}
                meta={[
                    { charset: 'utf-8' },
                    { property: 'og:type', content: 'website' },
                    { property: 'og:locale', content: 'en_US' },
                    { name: 'twitter:card', content: 'summary_large_image' },
                    { name: 'twitter:site', content: '@CyberZero.AI' },
                    { name: 'twitter:creator', content: '@CyberZero.AI' },
                    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
                    { name: 'robots', content: 'index, follow' },
                    { name: 'theme-color', content: '#2C3E50' },
                    { name: 'application-name', content: 'CyberZero AI' },
                    { name: 'apple-mobile-web-app-capable', content: 'yes' },
                ]}
            />

            {/* Hero Section */}
            <Hero
                title="Password Strength Checker"
                description="Ensure your passwords are strong and secure by checking their strength."
                imageUrl="/assets/password/strength-checker/password-strength-checker-banner.png"
                imageAlt="Password Strength Checker"
            />

            {/* Password Strength Checker Form */}
            <div className={styles.passwordStrengthCheckerForm}>
                <div className={styles.inputGroup}>
                    <label htmlFor="password">Enter Password:</label>
                    <div className={styles.passwordInputWrapper}>
                        <input
                            type="password"
                            id="password"
                            value={password}
                            onChange={handlePasswordChange}
                            placeholder="Enter your password"
                        />
                        <button
                            onClick={copyToClipboard}
                            title="Copy Password"
                            className={styles.copyButton}
                        >
                            <FontAwesomeIcon icon={faCopy} />
                        </button>
                    </div>
                </div>

                <div className={styles.inputGroup}>
                    <label htmlFor="keywords">Enter Keywords (comma-separated):</label>
                    <textarea
                        id="keywords"
                        value={keywords}
                        onChange={handleKeywordsChange}
                        placeholder="Enter keywords like your name, date of birth, etc."
                    ></textarea>
                </div>

                <div className={`${styles.strength} ${getStrengthClass(strength)}`}>
                    Password Strength: {strength}
                </div>
            </div>
        </div>
    );
};

export default PasswordStrengthChecker;
